import AbstractParentEntitiesQuery from './AbstractByGuidQuery'
import QueryHandlerInterface from './QueryHandlerInterface'
import AbstractQueryRepository from '../../domain/repository/AbstractQueryRepository'

export default abstract class AbstractParentEntitiesQueryHandler implements QueryHandlerInterface
{
    private repo: AbstractQueryRepository;

    constructor(repo: AbstractQueryRepository)
    {
        this.repo = repo;
    }

    execute(query: AbstractParentEntitiesQuery): Promise<any>
    {
        return this.repo.getParentRecords(query.getGuid());
    }
}